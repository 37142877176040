body{
    background-color: #F3FEFC;
}
.explain {
    background-color: #002420;
    margin-left: -15px;
}

.gather{
    margin:5% auto;
}

.tizeti-logo img {
    width: 25%;
    margin-left: 37%;
}

.login {
    margin-top: 10%;
}

.login-form {
    margin: 7%;
    /* width: 82%;
    padding-left: 5%; */
}

/* .remember {
    margin-top: 3%;
    font-size: 12px;
}

.remember input {
    margin-top: 4px;
} */

.login-form .col-sm-12,
.login .col-sm-12,
.remember .col-sm-12,
.remember .col-sm-6 {
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.remember p {
    text-align: end;
    margin-top: 6px;
}

.remember p a {
    color: #000000;
}

.wrapper-button {
    text-align: center;
    margin-top: -5%;
}

#content1 button,
#content2 button{
    width: 75%;
}

.form-control{
    border: 1px solid #35BEAA;

}

.login-form .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    margin-left: 13%;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section {
    display: none;
    margin-top: -6%;
}

.radio {
    display: none;
}

.label2 {
    display: inline-block;
    margin: 0 0 0px;
    padding: 10px 0px;
    font-weight: 600;
    text-align: center;
    color: #000000;
    border: 1px solid transparent;
}

label {
    display: inline-block;
    /* margin: 0 0 -1px; */
    padding: 15px 67px;
    font-weight: 600;
    text-align: center;
    color: #000000;
    border: 1px solid transparent;
}

label:hover {
    color: #789;
    cursor: pointer;
}

input:checked+label {
    color: #002420;
    border-bottom: 3px solid #16C2AE;
}

.signn {
    margin-left: 14%;
}

#tab1:checked~#content1,
#tab2:checked~#content2,
#tab3:checked~#content3,
#tab4:checked~#content4 {
    display: block;
}


@media(max-width:768px){
    label {
        display: inline-block;
        /* margin: 0 0 -1px; */
        padding: 15px 30px !important;
        font-weight: 600;
        text-align: center;
        color: #000000;
        border: 1px solid transparent;
    }
}

@media(max-width:1024px){
    label {
        display: inline-block;
        /* margin: 0 0 -1px; */
        padding: 15px 40px;
        font-weight: 600;
        text-align: center;
        color: #000000;
        border: 1px solid transparent;
    }
}
