body {
  font-family: Rubik;
  background-color: #f3fefc;
}

.gather {
  margin: 5% auto;
}

.fa-phone {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari 3-8 */
  transform: rotate(90deg);
}

.fa-file-download,
.fa-file-upload,
.fa-cloud-upload-alt,
.fa-file-image {
  font-size: 50px;
  color: #23464a;
}

.identity-type {
  width: 87%;
}

.Logo {
  background: url(../../src/images/PopUpBackground.png);
  min-height: max-content;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.download-dashboard {
  background: url(../../src/images/DownloadAppDashboard.png);
  min-height: max-content;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.password-wrapper {
  position: relative;
  display: flex;
}

i.pointer {
  position: absolute;
  top: 27%;
  right: 3%;
}

i:hover.pointer {
  cursor: pointer;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  height: 100%;
  right: 0;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.right .modal-body {
  padding: 15px 0px 80px;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

.modal-content {
  border-radius: 0;
  border: none;
}

.fontWeight500 {
  font-weight: 500;
}

.fontWeight900 {
  font-weight: 900;
  font-family: Rubik;
  font-size: 20px;
}

/* .card-verify{
    height:78%;
} */
.login {
  margin-top: 10%;
}

.page-todo {
  margin: 0px 5px 0px 5px !important;
  padding-left: 10% !important;
  padding-right: 10% !important;
}
.wrapping-word {
  overflow-wrap: break-word;
}
.tizeti-logo img {
  width: 25%;
  margin-left: 37%;
}

.login-form {
  margin: 7%;
}

.csv-part {
  justify-content: space-between;
}

.body {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#restyle {
  width: 6%;
}

.dashboard-image {
  margin-left: 5%;
}

.carry-box {
  margin-top: 0 !important;
}

.two-twelves {
  flex-flow: column;
}

.navbar-light {
  background-color: #0f676b;
}

#pills-tab .nav-item {
  border: 2px solid #0f676b;
  border-radius: 2px;
  background-color: white;
}
/* 
#pills-tab .nav-item.active{
    background-color: #0F676B;
} */

#pills-tab .nav-item a.active {
  background-color: #0f676b;
  color: white;
  border-radius: 0px;
}

#pills-tab .nav-item a {
  color: #0f676b;
}

#bulk-sms {
  justify-content: flex-end;
}

#planss .dropdown-item:active {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 2px solid #25bca6;
  background-color: #25bca6;
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}

#graph-nav li {
  border: 2px solid #1b3f43;
  background-color: white;
  border-radius: 7px;
}

#graph-nav li a {
  color: #1b3f43;
}

#graph-nav .active {
  background-color: #1b3f43;
  color: white;
}

.navbar {
  padding: 0rem 1rem;
}
.add-section {
  color: white;
}

.col-lg-12 {
  padding-right: 0px;
}

.span-img {
  height: 16px;
  vertical-align: initial;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:active {
  color: white;
  border-bottom: 3px solid white;
}

.button-section {
  justify-content: flex-end;
  flex-flow: wrap-reverse;
}

.subing {
  margin-bottom: 15%;
}
.small-small {
  margin-right: -9%;
}

.btn {
  border-radius: 3px;
  /* box-shadow: 0px 2px 22px -10px ; */
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

.btn-green {
  background-color: #25bca6;
  border: 0;
  box-shadow: 3px 1px 10px -2px #e4eeee;
}
.second-button {
  background-color: #0f676b;
  border: 0;
  box-shadow: 3px 1px 10px -2px #e4eeee;
}

.boxes {
  position: relative;
  display: block;
  margin-bottom: 12px;
  overflow: hidden;
  padding: 8%;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background: white;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  height: 150px;
  /* justify-content: end; */
}

.modal-title {
  color: #27a8b4;
}

.box-carrier {
  justify-content: space-between;
  flex-flow: wrap;
}

.baby-font {
  font-size: 65%;
}

.card-group {
  justify-content: space-between;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.wallboard-table {
  justify-content: space-between;
}

.wallboard-chair {
  top: -70%;
}
.copy-font {
  font-size: 13px;
}

.font15 {
  font-size: 15px;
}

.wallboard-filter select {
  padding: 1%;
  outline: 0;
  border: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.acct {
  border: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.wallboard-table .form-control,
.button-section .form-control {
  width: 80%;
  outline: 0;
  border: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

#profile .form-control,
#buttons .form-control,
#webcall .form-control,
#editqueue .form-control,
#editcon .form-control,
#payment-page .form-control,
#addivr .form-control,
#ivropt .form-control,
#lot-form .form-control,
#changePassword .form-control,
#phone .form-control,
#edit-phone .form-control,
#pills-robo .form-control,
#campaign .form-control,
#action-label .form-control {
  outline: 0;
  border: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

#buttons .form-control:active {
  border: 2px solid #0f676b;
}

#confirmdelete img {
  width: auto;
}

#ton {
  justify-content: center;
}

#buttons button {
  background-color: white;
  border: 2px solid #25bca6;
}

.second-child {
  margin-top: 3rem;
}

.processing-page {
  margin: 8% auto;
  height: 250px;
}

.rotating-img {
  margin-top: 22%;
}

#ton .first-child {
  background-color: white;
  border: 2px solid #0f676b;
  color: #0f676b;
}

.wallboard-table label {
  margin: 0;
  padding: 0;
}
.make-equal {
  margin-top: 3.5%;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0.1rem 1rem #e6f5f6;
}

.disable-select select {
  border: 0;
}

.badge-info {
  color: black;
  background-color: #80808047;
}

.disable-select select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

.subscriber-img img {
  width: 80%;
  cursor: pointer;
}

.saving {
  position: relative;
}

.saving b {
  font-size: 23px;
}

.saving::before {
  content: '\20A6';
  font-size: 22px;
  font-weight: bold;
  font-family: sans-serif;
  /* left: -26px;
    top: 8px; */
}

.dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: whitesmoke;
}

.navbar-light .navbar-nav .show > .nav-link {
  color: white;
}

.amount {
  font-family: sans-serif;
}

.download-image,
.add-section img {
  cursor: pointer;
}

.wallet-balance {
  display: -webkit-inline-box;
}

.profile-logout {
  cursor: pointer;
}

.dropdown-menu {
  min-width: max-content;
}

.dropdown-menu li a {
  color: black;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .dashboard-image img {
    width: 12%;
    height: 15%;
  }

  #restyle {
    width: 12%;
  }

  .boxes {
    position: relative;
    display: block;
    margin-bottom: 12px;
    overflow: hidden;
    padding: 12%;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    background: white;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    height: 170px;
  }
  .col-lg-12 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .topup {
    border-radius: 3px;
    background-color: #3bb427;
    color: white;
    cursor: pointer;
  }

  .btn-greenn {
    background-color: #3bb427;
    color: white;
  }

  .box-carrier .col-lg-3 {
    padding: 0;
  }

  .small-small {
    margin-right: 0;
  }
  .subing {
    margin-bottom: 0;
  }

  .shift-up {
    margin-top: 1rem !important;
  }

  .two-twelves {
    flex-flow: row;
  }

  .second-child {
    margin-top: 1.5rem;
  }
  .wallboard-chair {
    top: 0;
  }
  .carry-box {
    margin-top: -3% !important;
  }
  #label-switch .switch {
    display: inline-block;
    /* margin: 0 0 -1px; */
    padding: 10px 20px !important;
    font-weight: 600;
    text-align: center;
    color: #000000;
    border: 1px solid transparent;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-image img {
    width: auto;
  }
}

/* switch styling */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

/* label{
    padding: 10px 19px;
  }
   */
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: inherit;
  width: inherit;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3bb427;
}

/* input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  } */

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.table-row {
  cursor: pointer;
}

#dialpad {
  flex-flow: wrap;
}

.notify {
  background-color: yellow;
}
.digits button {
  background-color: white;
  border: 1px solid black;
}

.digits button:active {
  background-color: black;
  color: white;
}

.digits button:visited {
  background-color: black;
  color: white;
}

.receive {
  height: '30px';
  width: '100%';
}

.call-button {
  cursor: pointer;
  margin-top: 35%;
}

.signs img {
  vertical-align: inherit;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.resize-small {
  font-size: 60%;
}

#resize-fluid {
  padding-left: 5%;
  padding-right: 5%;
}

.payment {
  position: fixed;
}

.payment-check {
  width: 10%;
}
.payment-content {
  margin-top: 15%;
}

.payment-holder {
  padding-top: 30%;
}

@media screen and (min-width: 250px) and (max-width: 550px) {
  .notifyFont {
    font-size: 14px;
  }
  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    height: 30rem;
  }
}

@media screen and (min-width: 550px) {
  .notifyFont {
    font-size: 17px;
  }
}

@media screen and (min-width: 1024px) {
  .notifyFont {
    font-size: 20px;
  }
}
