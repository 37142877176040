.payg-input{     border: 2px solid rgb(51, 192, 172);
    padding: .375rem .75rem;
    color: #495057;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}
.payg-input:focus{

    outline: none;

}
.inputs{
    display: grid;
    grid-template-columns: 70% auto;
    grid-column-gap: 1rem;
    padding: 3%;
}
.information{display: grid;
    text-align: center;}
